@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
html,
body,
.App {
  background-color: black;
  color: white;
  font-family: 'Montserrat', sans-serif;
}
h1 {
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 3rem !important;
}
.project h1 {
  background: -webkit-linear-gradient(left, #71c0c3, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 575.98px) {
  h1 {
    font-size: 2rem !important;
  }
}
