.navbar-custom {
  background-color: black;
}
.bg-custom .nav-link {
  color: white;
  background-color: transparent;
  transition: all 0.3s ease-in;
}
.bg-custom .nav-link:hover,
.bg-custom .nav-link:focus {
  color: #72be44;
}
.navbar-collapse,
.navbar-nav {
  background-color: transparent;
}
.navbar-toggler {
  color: white !important;
  border-color: white !important;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

footer {
  background-color: white;
  color: black;
}
footer p {
  margin-bottom: 0;
}
footer ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  padding-left: 0;
}
footer ul li {
  padding-right: 5%;
}
@media (max-width: 575.98px) {
  .navbar-custom .navbar-brand {
    width: 40%;
  }
  .bg-custom .nav-link {
    background-color: white;
    color: black;
    text-align: center;
  }
  .bg-custom .nav-link:hover,
  .bg-custom .nav-link:focus {
    background-color: black;
    color: white;
  }
  footer p {
    text-align: center;
  }
  footer ul {
    padding-top: 5%;
    justify-content: center;
  }
}
