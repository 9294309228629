.landing-overlay {
  position: absolute;
  z-index: 9;
  width: 50%;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
}
.why-landing h1 {
  background: -webkit-linear-gradient(left, #384ea1, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.why-landing p {
  text-align: justify;
}
.reels-home h1 {
  background: -webkit-linear-gradient(left, #ec1c24, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.reels-home a {
  color: white;
}
.reels-home a:hover,
.reels-home a:focus {
  color: #ece709;
  text-decoration: none;
}
.contact h1 {
  background: -webkit-linear-gradient(left, #ede80a, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
