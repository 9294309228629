.about h1 {
  background: -webkit-linear-gradient(left, #b3559f, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wwd h1 {
  background: -webkit-linear-gradient(left, #72be44, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wwd h6 {
  padding-top: 5%;
  padding-bottom: 5%;
}
.teams h1 {
  background: -webkit-linear-gradient(left, #71c0c3, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.teams img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
  transition: all 0.4s ease-in-out;
  border-radius: 100%;
}
.teams img:hover,
.teams img:focus {
  filter: none;
  -webkit-filter: grayscale(0%);
  border-radius: 0;
}
.teams h4 {
  text-transform: capitalize;
  font-weight: 300;
}
@media (max-width: 575.98px) {
  .about h4 {
    font-size: 1rem !important;
    margin-top: 40%;
  }
}
